
import { parentsFilter } from './helpers';

/*
 * Maybe inline the script on pages with the form on it, so there is no point
 * where the labels aren't showing on the form
 */
const body = document.body;
const forms = Array.from(document.querySelectorAll('.gform_wrapper'));



const doInputStuff = (input) => {
  if (input.tagName !== 'INPUT' && input.tagName !== 'TEXTAREA') return false;
  if (input.type && (input.type === 'radio' || input.type === 'checkbox')) return false;
  return true;
};

forms.forEach((el) => {
  const labels = Array.from(el.querySelectorAll('label'));
  labels.forEach((label) => {
    const container = label.nextElementSibling;
    if (container == null || ! container.classList.contains('ginput_container')) return;
    const lastElementChild = container.lastElementChild;
    if (!doInputStuff(lastElementChild)) return;
    if (lastElementChild.value.trim() !== '') lastElementChild.classList.add('input-open');
    container.appendChild(label);
  });
});

body.classList.add('show-labels');


document.addEventListener('change', (e) => {
  const { target } = e;
  const gformBody = parentsFilter(target, ['.gform_body', '.search']);
  if (gformBody.matches('.gform_body') || gformBody.matches('.search')) {
    if (!doInputStuff(target)) return;
    const isOpen = target.classList.contains('input-open');
    const shouldBeOpen = target.value.trim() !== '';
    if (isOpen && shouldBeOpen) return;
    if (shouldBeOpen) return target.classList.add('input-open');
    return target.classList.remove('input-open');
  }
});