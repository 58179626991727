import './polyfills';
import './select';
import './mobile-nav';
import './home-banner';
import './home-carousel';
import './form';
import './faq';
import './table';
import './search';
import './header';
import './nav';
import './contact';
import './cookiebar';