const defaults = {
  ignoreClicks: ['.lightbox__content'],
};

export default (userSettings = {}) => {

  const settings = Object.assign({}, defaults, userSettings);

  const lightbox = document.createElement('DIV');
  lightbox.classList.add('lightbox');
  lightbox.innerHTML = `
    <div class="lightbox__inner">
      <button class="lightbox__close">Close</button>
      <div class="lightbox__content">
      </div>
    </div>
  `;
  const inner = lightbox.querySelector('.lightbox__inner');
  const closeButton = lightbox.querySelector('.lightbox__close');
  const content = lightbox.querySelector('.lightbox__content');
  const body = document.body;

  let state = 'CLOSED'; // CLOSED, CLOSING, OPENING, OPEN

  const open = () => {
    state = 'OPENING';
    body.style.overflow = 'hidden';
    lightbox.classList.add('lightbox--block');

    requestAnimationFrame(() => { // wait a frame so transition takes place properly
      if (state !== 'OPENING') return;
      lightbox.classList.add('lightbox--show');
    });

  };

  const returnLightbox = () => {
    return lightbox;
  }

  const changeHTML = (html) => {
    content.innerHTML = html;
  };

  const close = () => {
    state = 'CLOSING';
    body.style.overflow = '';
    lightbox.classList.remove('lightbox--show');
  };

  lightbox.addEventListener('click', ({target}) => {
    if (target === lightbox || target === closeButton) {
      close();
    }
  });


  lightbox.addEventListener('transitionend', () => {
    if (state !== 'OPENING' && state !== 'CLOSING') return;
    if (state === 'OPENING') state = 'OPEN';
    else if (state === 'CLOSING') {
      state = 'CLOSED';
      lightbox.classList.remove('lightbox--block');
    }
  });

  document.addEventListener('keydown', (e) => {
    if ( (state === 'OPEN' || state === 'OPENING') && (e.code && e.code === 'Escape' || e.keyCode === 27) ) {
      close();
    }
  });

  document.body.appendChild(lightbox);


  return {
    open,
    close,
    changeHTML,
    returnLightbox,
  };

};
