import { parentsFilter } from './helpers'; 

const nav = document.querySelector('.header__nav');


// https://github.com/jimmaaay/touchFriendlyDropdowns
const doubletaptogo = () => {
  const selectors = [];
  let currentItem = false;

  const addElements = obj => selectors.push(obj);

  // use similar to $($0).parents('.selector')
  const parents = (e, selector) => {
    let el = e;
    while(! el.matches(selector)) {
      el = el.parentElement;
      if (el == null) {
        el = false;
        break;
      }
    }
    return el;
  };
  
  const documentClick = (e) => {
    const { target } = e;
    selectors.forEach((selector) => {
      const targetMatches = target.matches(selector);
      let parentMatches = false;
      if (!targetMatches) parentMatches = parents(target, selector);

      if (targetMatches || parentMatches !== false) {
        const el = targetMatches ? target : parentMatches;
        if (currentItem !== el) {
          currentItem = el;
          e.preventDefault();
        }
      } else {
        currentItem = false;
      }
    });
  };

  if ('ontouchstart' in window) {
    document.addEventListener('click', documentClick, false);
  };

  return addElements;
};



// deals with navigation overflowing the page when a sub menu is shown
const body = document.body;

const onShowNav = ({target}) => {
  const ul = target.nextElementSibling;
  if (ul == null) return;
  if (ul.tagName !== 'UL') return;
  const { right } = ul.getBoundingClientRect();
  if (right > window.innerWidth || right > body.offsetWidth) {
    ul.classList.add('sub-menu--left');
  }
};


const navMenuA = Array.from(document.querySelectorAll('.header__nav .menu-item-has-children > a'));

navMenuA.forEach((el) => {
  el.addEventListener('mouseover', onShowNav);
  el.addEventListener('mouseout', () => {
    el.nextElementSibling.classList.remove('sub-menu--left');
  });
});

const addElements = doubletaptogo();
addElements('.header__nav .menu-item-has-children > a');