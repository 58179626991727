import { parentsFilter } from './helpers';
import lightboxInit from './lightbox';


const body = document.body;
const searchPopup = document.querySelector('.search-popup');
const { 
  open,
  close,
  changeHTML,
  returnLightbox,
} = lightboxInit();

const input = searchPopup.querySelector('input');
const lightbox = returnLightbox();
if (input.value.trim() !== '') input.classList.add('input-open');

changeHTML(searchPopup.innerHTML);




document.addEventListener('click', (e) => {
  const { target } = e;
  const searchButton = parentsFilter(target, ['.asl__search']);
  if (searchButton.matches('.asl__search')) {
    e.preventDefault();
    open();
    setTimeout(() => {
      lightbox.querySelector('input').focus();
    }, 300);
  }
});
