const active = document.querySelector('.header__bar__country__active');
const options = document.querySelector('.header__bar__country__options');
const select = document.querySelector('.header__select__select');

active.addEventListener('click', () => {
  active.classList.toggle('header__bar__country__active--open');
  options.classList.toggle('header__bar__country__options--open');
});

select.disabled = false;
select.addEventListener('change', () => {
  if (select.value !== '') {
    window.location = select.value;
  }
});
