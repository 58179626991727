import { lory } from 'lory.js';
import isEqual from 'lodash.isequal';


const carousel = document.querySelector('.home-box__carousel');
const noSlides = document.querySelectorAll('.home-box__carousel__item').length;


if (carousel != null) {

  const next = carousel.querySelector('.js_next');
  const prev = carousel.querySelector('.js_prev');

  // centeres the boxes if there are less than 4 at 75em
  if (noSlides < 4) {
    const style = document.createElement('STYLE');
    style.innerHTML = `
      @media (min-width: 75em) {
        .home-box__carousel__slides {
          transform: none !important;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }
      }
    `;

    document.body.appendChild(style);
  }

  const options = [
    {
      breakpoint: null,
      settings: {
        infinite: 1,
      },
    },
    {
      breakpoint: '(min-width: 43.75em)',
      settings: {
        infinite: 2 > noSlides ? false : 2,
      },
    },
    {
      breakpoint: '(min-width: 75em)',
      settings: {
        infinite: 4 > noSlides ? false : 4,
      },
    },
  ];

  const getValidOption = () => {
    return options.reduce((ret, obj) => {
      if (obj.breakpoint == null || ret === false || window.matchMedia(obj.breakpoint).matches) return obj.settings;
      return ret;
    }, false);
  };

  let carouselOptions = getValidOption();


  const buttonState = (opts) => {
    if (opts.infinite === false) {
      next.style.display = 'none';
      prev.style.display = 'none';
    } else {
      next.style.display = '';
      prev.style.display = '';
    }
  };

  carousel.addEventListener('after.lory.init', () => {
    const newOptions = getValidOption();
    buttonState(newOptions);
  });

  carousel.addEventListener('on.lory.resize', () => {
    const newOptions = getValidOption();
    buttonState(newOptions);
    if (isEqual(carouselOptions, newOptions)) return;
    carouselOptions = newOptions;
    loryCarousel.setup();
  });

  const loryCarousel = lory(carousel, carouselOptions);

}


