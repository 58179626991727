const selectPlaceholder = Array.from(document.querySelectorAll('.select--placeholder'));


const setClasses = (el) => {
  const val = el.value;
  if (val === '') el.classList.add('select--placeholder-value');
  else el.classList.remove('select--placeholder-value');
};

// set initial classes
selectPlaceholder.forEach(setClasses);


document.body.addEventListener('change', (e) => {
  if(! e.target.classList.contains('select--placeholder')) return;
  setClasses(e.target);
});