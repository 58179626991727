import 'inert-polyfill';

const mobileMenu = document.querySelector('.mobile-menu');
const label = document.querySelector('.header__top__toggle');
const checkbox = document.querySelector('#menu-toggle');
const body = document.body;
const root = document.documentElement;

let isMenuOpen = checkbox.checked;

const checkboxChange = () => {
	isMenuOpen = checkbox.checked;
	if (isMenuOpen) {
    root.classList.add('mobile-menu-open');
		mobileMenu.inert = false;
		mobileMenu.scrollTop = 0;
    window.scrollTo(0,0);
  }
	else {
    root.classList.remove('mobile-menu-open');
    mobileMenu.inert = true;
  }
};

checkboxChange(); // call once as the script defers so menu could already be open

checkbox.addEventListener('change', checkboxChange);

// Accessibility Stuff, see link below
//https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_button_role

label.addEventListener('keydown', (e) => {
	if (e.hasOwnProperty('code')) {
		if (e.code === 'Enter' || e.code === 'Space') {
			checkbox.checked = !checkbox.checked;
			checkboxChange();
		}
	} else {
		if (e.keyCode === 13 || e.keyCode === 32) {
			checkbox.checked = !checkbox.checked;
			checkboxChange();
		}
	}
});