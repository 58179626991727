const maps = Array.from(document.querySelectorAll('.office-location__map'));

if (maps.length !== 0) {
  const initMaps = () => {
    maps.forEach((el) => {
      const latLngString = el.getAttribute('data-latLng').split(',');
      const lat = parseFloat(latLngString[0], 10);
      const lng = parseFloat(latLngString[1], 10);
      const map = new google.maps.Map(el, {
        center: {
          lat,
          lng,
        },
        zoom: 14,
      });
      const marker = new google.maps.Marker({
        map,
        position: {
          lat,
          lng,
        },
      });
      
    });
  };

  if (window.mapsJS === false) window.initMap = initMaps;
  else initMaps();
}