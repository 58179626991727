import { lory } from 'lory.js';


const homeBanner = document.querySelector('.banner--home');

if (homeBanner) { 
  let timeout = null;
  let timeoutLocked = false;
  const slider = homeBanner.querySelector('.js_slider');
  const ul = slider.querySelector('.js_slides');
  const dotCount = slider.querySelectorAll('.js_slide').length;
  const dotContainer = slider.querySelector('.js_dots');
  const dotListItem = document.createElement('LI');
  const next = homeBanner.querySelector('.js_next');
  const prev = homeBanner.querySelector('.js_prev');

  homeBanner.classList.add('banner--lory');

  const resetLoop = () => {
    if (timeoutLocked) return;
    clearTimeout(timeout);
    loop();
  };

  const handleSlides = ({ slides, currentSlide }) => {
    slides.forEach((el, i) => {
      const button = el.querySelector('.banner__slide__button');
      if (i === currentSlide) button.tabIndex = 0; // make tabbable
      else button.tabIndex = -1; // make not tabbable
    });
  };

  const buttonClick = () => {
    resetLoop();
  };

  // taken from lory homepage http://meandmax.github.io/lory/
  const handleDotEvent = (e) => {
    if (e.type === 'before.lory.init') {
      for (var i = 0, len = dotCount; i < len; i++) {
        var clone = dotListItem.cloneNode();
        dotContainer.appendChild(clone);
      }
      dotContainer.childNodes[0].classList.add('active');
    }
    if (e.type === 'after.lory.init') {
      for (var i = 0, len = dotCount; i < len; i++) {
        dotContainer.childNodes[i].addEventListener('click', function(e) {
          homeSlider.slideTo(Array.prototype.indexOf.call(dotContainer.childNodes, e.target));
          resetLoop();
        });
      }
    }
    if (e.type === 'after.lory.slide') {
      for (var i = 0, len = dotContainer.childNodes.length; i < len; i++) {
        dotContainer.childNodes[i].classList.remove('active');
      }
      dotContainer.childNodes[e.detail.currentSlide - 1].classList.add('active');
    }
    if (e.type === 'on.lory.resize') {
      for (var i = 0, len = dotContainer.childNodes.length; i < len; i++) {
        dotContainer.childNodes[i].classList.remove('active');
      }
      dotContainer.childNodes[0].classList.add('active');
    }
  };

  /*
   * Need to make sure the buttons in the cloned elements are not tabbable
   */
  slider.addEventListener('after.lory.init', (e) => {
    const slides = Array.from(ul.children);
    const activeSlide = ul.querySelector('.active');
    const currentSlide = slides.indexOf(activeSlide);
    handleSlides({
      slides,
      currentSlide,
    });
  });

  /*
   * The below handles the issue when someone tabbed that the slide would scroll half 
   * into view. This was because it was changing the scrollLeft value of .js_frame.
   * So the solution was to stop the button being tabbable when the slide is not in view
   */
  slider.addEventListener('after.lory.slide', (e) => {
    const slides = Array.from(ul.children);
    const { currentSlide } = e.detail;
    handleSlides({
      slides,
      currentSlide,
    });
    // loop();
    resetLoop();
  });

  slider.addEventListener('before.lory.init', handleDotEvent);
  slider.addEventListener('after.lory.init', handleDotEvent);
  slider.addEventListener('after.lory.slide', handleDotEvent);
  slider.addEventListener('on.lory.resize', handleDotEvent);
  slider.addEventListener('mouseenter', () => {
    timeoutLocked = true;
    clearTimeout(timeout);
  });
  slider.addEventListener('mouseleave', () => {
    timeoutLocked = false;
    resetLoop();
  });

  next.addEventListener('click', buttonClick);
  prev.addEventListener('click', buttonClick);

  const homeSlider = lory(slider, {
    infinite: 1,
  });

  const loop = () => {
    timeout = setTimeout(() => {
      homeSlider.next();
    }, 5000);
  };

  loop();
  

}