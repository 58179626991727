export const parentsFilter = (e, array) => {
  const selectorArray = array.concat(['body']);
  const continueLoop = (element) => {
    return selectorArray.reduce((ret, selector) => {
      if (ret === false) return false;
      if (element.matches(selector)) return false;
      return true;
    }, true);
  };
  let el = e;
  while(continueLoop(el)) {
    el = el.parentElement;
  }
  return el;
};