(function (key, width) {
  if (localStorage.getItem(key)) return;
  localStorage.setItem(key, 1);

  var cookieBar = document.createElement('aside');
  cookieBar.innerHTML = '<div class="cookie-wrap" style="max-width: ' + width + 'px; padding: 1rem 0; margin: 0 auto;">We use cookies to analyse our website traffic and page usage. By continuing to use our website you consent to these cookies. Read our <a href="'+ mainArgs.privacyPolicy +'" style="color: #f9b82b;">privacy policy</a> for more information.<button style="background: none; border: none; box-shadow: none; margin-left: 0.3rem; font: inherit; color: #b51e52; padding: 0; cursor: pointer; background-color: #f9b82b; padding: 0 0.5rem;" type="button">Ok, got it</button></div>';
  cookieBar.style.cssText = "color: #FFF; background: #b51e52; font-size: 15px; z-index: 100; opacity: 0.9; padding: 5px 20px; box-sizing: border-box; position: fixed; width: 100%; right: 0; bottom: 0;";
  cookieBar.querySelector('button').addEventListener('click', function() {
    cookieBar.parentNode.removeChild(cookieBar);
  });
  document.body.appendChild(cookieBar);
})('seen_the_cookie_bar', 1332);