import { parentsFilter } from './helpers';
import dCarousel from './vendor/d-carousel';

import 'van11y-accessible-accordion-aria';

const faqs = document.querySelector('.faqs');


if (faqs != null) {
  const faqContent = faqs.querySelector('.faqs__content');
  const jsAccordion = faqs.querySelector('.js-accordion');
  const filters = faqs.querySelector('.faqs__filter');

  const filterButtons = Array.from(filters.querySelectorAll('.faqs__filter__item__inner'));
  const possibleFilters = filterButtons.map(el => el.getAttribute('data-key'));
  const styleTag = faqs.querySelector('style');

  const hashChange = () => {
    const hash = window.location.hash
      .replace('#', '')
      .trim();
    const activeFilter = possibleFilters.indexOf(hash) !== -1 ? hash : possibleFilters[0];

    const prev = filters.querySelector('.faqs__filter__item__inner.active');
    if (prev != null) prev.classList.remove('active');

    filters
      .querySelector(`.faqs__filter__item__inner[data-key=${activeFilter}]`)
      .classList.add('active');

    if (activeFilter === 'all') {
      return styleTag.innerHTML = `
      .js-accordion [data-hide-view-all] {
        display: none !important;
      }
    `;
    }

    // let css deal with it
    styleTag.innerHTML = `
      .js-accordion > *:not([data-cat=${activeFilter}]) {
        display: none !important;
      }
    `;

  };


  dCarousel(faqs.querySelector('.d-carousel'), {
    paddingLeft: true,
  });

  setTimeout(() => { 
    // use setTimeout 0 to wait for the accordion to init
    // had to use 100 as firefox was not working correctly with 0

    Array.from(jsAccordion.querySelectorAll('.faqs__list-accordion__header'))
      .forEach((button) => {
        const panel = button.nextElementSibling;
        const dataCat = panel.getAttribute('data-cat');
        const hasExcludeAttribute = panel.getAttribute('data-hide-view-all') != null;
        if (hasExcludeAttribute) {
          button.setAttribute('data-hide-view-all', true);
        }
        button.setAttribute('data-cat', dataCat);
      });

    hashChange();

    faqs.classList.add('faqs--init');

  }, 100);


  window.addEventListener('hashchange', hashChange);
  filters.addEventListener('click', (e) => {
    const { target } = e;
    const button = parentsFilter(target, ['.faqs__filter__item__inner']);
    if (button.matches('.faqs__filter__item__inner')) {
      const key = button.getAttribute('data-key');
      /*
       *  If don't want the filter to add to the history state then instead of 
       *  calling window.location.hash should call history.replaceState with 
       *  appropriate params. And possibly add popstate listener
       */
      window.location.hash = `#${key}`;
    }
  });


}