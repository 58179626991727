import { parentsFilter } from './helpers';


const tableStyle2 = Array.from(document.querySelectorAll('.table--style-2'));
const { tableData } = window;

let openTooltip = false;

document.addEventListener('click', (e) => {
  const { target } = e;

  const el = parentsFilter(target, ['.table__tooltip', '.table__tooltip__content__close']);

  if (el.matches('.table__tooltip')) {

    const isOpen = el.classList.contains('table__tooltip--open');
    if (!isOpen) {
      el.classList.add('table__tooltip--open');
      if (openTooltip !== false) openTooltip.classList.remove('table__tooltip--open');
      openTooltip = el;
    }

  }

  if (el.matches('.table__tooltip__content__close')) {
    const tableTooltip = parentsFilter(el, ['.table__tooltip']);
    tableTooltip.classList.remove('table__tooltip--open');
    openTooltip = false;

  }

});


document.addEventListener('keydown', (e) => {
  if (openTooltip !== false && (e.code && e.code === 'Escape' || e.keyCode === 27)) {
    openTooltip.classList.remove('table__tooltip--open');
    openTooltip = false;
  }
});

tableStyle2.forEach((el, i) => {
  const coverage = Array.from(document.querySelectorAll('.table__content--coverage'));
  let HTML = false;

  let nos = [];
  let timesThingyOutter = false;

  // const HTML = '';

  coverage.forEach((cover, coverI) => {

    if (HTML === false) {
      const noChildren = cover.childElementCount;
      const timesThingy = 100 / noChildren;
      const inner = tableData.map((p, i) => {
        const percentage = parseFloat(p, 10) * 100;
        const noInPercentage = cover.querySelectorAll(`.table__column--style-${i}`).length;
        const width = `${noInPercentage * timesThingy}%`;
        const minWidth = `${noInPercentage * 4.1}rem`;
        const style = `width: ${width};min-width:${minWidth}`;
        if (coverI === 0) {
          nos.push(noInPercentage);
          timesThingyOutter = timesThingy;
        }
        // const style = 
        return `
          <div class="table__coverage__item" style="${style}">
            <span>${percentage}% cover</span>
          </div>
        `
      }).join('');

      HTML = `
      <div class="table__coverage">
        ${inner}
      </div>
      `;
    }

    const children = Array.from(cover.children);
    const noChildren = children.length;

    cover.insertAdjacentHTML('afterend', HTML);

  });

  const tableTitle = el.parentElement.querySelector('.table__titles');
  nos.forEach((noInPercentage, i) => {
    const title = document.createElement('P'); 
    const width = `${noInPercentage * timesThingyOutter}%`;
    const minWidth = `${noInPercentage * 4.1}rem`;
    const style = `width: ${width};min-width:${minWidth}`;
    title.innerHTML = window.tableTitles[i];
    title.setAttribute('style', style);
    tableTitle.appendChild(title);
  });
  console.log(nos, timesThingyOutter);


});